import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import Layout from "../components/layout"

export default () => {
    return (
        <Layout>
            <Container className="text-center">
                <Alert variant="danger" className="mt-4">
                    <p>Une erreur s'est produite lors de votre réservation !</p>
                    <p>Veuillez nous contacter: <a href='mailto:support@lesenfantsdelarbrevert.fr'>support@lesenfantsdelarbrevert.fr</a></p>
                </Alert>
            </Container>
        </Layout>
    )
}